import { getSession, providers, signIn } from 'next-auth/client';
import Page from '../components/Page';
import Server from '../server/Server';
import Links from '../navigation/Links';
import styles from '../assets/pages/login-page.module.scss';
import { HtmlButton } from '../components/HtmlButton';
import PageSection from '../components/PageSection';
import GoogleLogo from '../../public/icons/google.svg';
import { withRouter } from 'next/router';
import Meta from '../util/Meta';
import AuthProvider from '../data/AuthProvider';
import Util from '../util';

const Icons = {
	[AuthProvider.GOOGLE]: <GoogleLogo style={{ width: 20, height: 20 }} />,
};

function SignIn({ providers, router }) {
	const signInWithProvider = (providerId) => {
		if (!providerId) providerId = AuthProvider.GOOGLE;

		const callbackUrl = router.query?.from
			? `${process.env.NEXT_PUBLIC_WEBSITE_URL}${router.query?.from}`
			: null;
		signIn(providerId, { callbackUrl });
	};

	return (
		<Page padding={false} className={styles.landingPage} meta={Meta.Landing}>
			<PageSection
				fullScreen
				className={`${styles.landingBanner} small-padding`}
			>
				<div className={styles.landingBannerLeft}>
					<h1 className={`${styles.loginTitle} left-aligned`}>
						Recognition for your Awesome Work
					</h1>
					<p className={`${styles.loginSubtitle} left-aligned`}>
						Grow individual careers | Strengthen teams | Grow companies
					</p>
					<br />
					{Object.values(providers).map((provider) => (
						<div key={provider.name} className={`${styles.loginButtons}`}>
							<HtmlButton
								type="filled"
								color="purple"
								className="provider-button square"
								size="small"
								onClick={() => signInWithProvider(provider.id)}
							>
								{Icons[provider.id]} <span>Sign in with {provider.name}</span>
							</HtmlButton>
						</div>
					))}
				</div>
				<div className={styles.bannerImage}>
					<img
						className={styles.desktopBanner}
						src="/images/top-fold-banner.png"
						alt="swan-desktop"
						style={{ marginRight: 0 }}
					/>
					<img
						className={styles.mobileBanner}
						src="/images/top-fold-banner-mobile.png"
						alt="swan-mobile"
					/>
				</div>
			</PageSection>
			<PageSection
				className={`${styles.imageTextSection} large-padding remove-bottom-padding`}
			>
				<div className="centered">
					<div className={styles.imageContainer}>
						<img src="/images/feature1.png" alt="feature" />
					</div>
				</div>
				<div className="centered">
					<div className={styles.imageTextSectionDescription}>
						Collect recognition for the great work you do in one place and share
						it publicly or keep it private. Appreciate your colleagues for their
						great work!
					</div>
				</div>
			</PageSection>
			<PageSection
				className={`${styles.imageTextSection} large-padding remove-bottom-padding revert`}
			>
				<div className="centered">
					<div className={styles.imageTextSectionDescription}>
						Use Anonymous Recognition to find out who are the colleagues who
						love working with you.
					</div>
				</div>
				<div className="centered">
					<div className={styles.imageContainer}>
						<img src="/images/feature2.png" alt="feature" />
					</div>
				</div>
			</PageSection>
			<PageSection className={`${styles.imageTextSection} large-padding`}>
				<div className="centered">
					<div className={styles.imageContainer}>
						<img src="/images/feature3.png" alt="feature" />
					</div>
				</div>
				<div className="centered">
					<div className={styles.imageTextSectionDescription}>
						Swanvo was built to help you accelerate career growth. We're
						building tools to help you understand and accelerate your path
						forward.
						<br />
						<br />
						<HtmlButton
							type="filled"
							size="large"
							color="purple"
							className="w-100-md square landing-button"
							onClick={() => signInWithProvider()}
						>
							Start Now!
						</HtmlButton>
					</div>
				</div>
			</PageSection>
			<style global jsx>{`
				header.ant-layout-header:not(.scrolled) {
					background: var(--background-color-landing);
				}
			`}</style>
		</Page>
	);
}

export default withRouter(SignIn);

export const getServerSideProps = async (context) => {
	const { query } = context;

	const session = await getSession(context);
	if (session)
		return Server.redirect(
			query?.from || Util.withQuery(Links.DASHBOARD, query)
		);

	return {
		props: {
			providers: await providers(context),
		},
	};
};
